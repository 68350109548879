import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useActiveClient } from "../lib/client";
import { HashDisplay } from "../Components";
import { ButtonOk } from "../Components/Buttons.js";
import { useAsset } from "./Asset.js";
import { Page, PageTitle } from "../Components/page.js";

function UniqueAssetRecord({hash}) {
  let navigate = useNavigate();
  let { asset } = useAsset({hash})

  if (!asset || !asset.type) return <></>;

  return <li  className="p-4 text-sm">
    <div onClick={() => navigate(`/asset/${hash}`)} className="cursor-pointer flex-row justify-between w-full  hover:underline">
      <p className="flex-1 font-mono font-semibold">{asset.content?.name}</p>
      <HashDisplay h={hash} />
    </div>
  </li>;
}

function QuantBalanceRecord({balance}) {
  let navigate = useNavigate();
  let { asset } = useAsset({ hash: balance.files[0] });

  if (!asset || !asset.type) return <></>;

  return <li  className="p-4 text-sm">
    <div onClick={() => navigate(`/quant/${balance.type}`)} className="cursor-pointer flex-row justify-between w-full hover:underline">
      <p className="flex-1 font-mono font-semibold">{asset.content?.name}</p>
      <p className="flex-1 font-mono">Amount: {balance.balance} {asset.content.units}</p>
      <HashDisplay h={balance.type} />
    </div>
  </li>;
}

export default function Inventory() {
  let navigate = useNavigate();
  let twinClient = useActiveClient()

  let { data: hashes } = useQuery({
    queryKey: ["GET", twinClient.url, "/toda"],
    placeholderData: [],
    queryFn: async function listFilesQuery() {
      return await twinClient.listFiles();
    }
  });

  let { data: balances } = useQuery({
    queryKey: ["GET", twinClient.url, "/dq"],
    placeholderData: [],
    queryFn: function balanceQuery() {
      return twinClient.balance();
    }
  });

  let uniqueAssetHashes = useMemo(() => {
    if (!hashes || hashes.length == 0) return [];
    let dqHashSet = new Set(balances.map(dq => dq.files).reduce((arr, hs) => arr.concat(hs), []));
    if (dqHashSet.size == 0) return hashes;
    return hashes.filter(h => !dqHashSet.has(h)).sort();
  }, [balances, hashes] )

  let fileInput = useRef(null);

  function createNewAsset(e) {
    navigate("/asset/create")
  }

  return <Page>
    <div className="py-10 px-2 flex flex-col items-center justify-center">
      <div className="p-6 w-full align-center">
        <PageTitle>Inventory</PageTitle>
        <ul className="~border-y-2 justify-center">
          {uniqueAssetHashes && uniqueAssetHashes.map(uah => <UniqueAssetRecord key={uah} hash={uah} />)}
          {balances && balances.map(b => <QuantBalanceRecord key={b.type} balance={b}/>)}
        </ul>
        <input type="file" ref={fileInput} hidden>
        </input>
        <div className="flex flex-col gap-6">
          <ButtonOk onClick={createNewAsset}>
            Create New Asset
          </ButtonOk>
          <ButtonOk onClick={() => navigate("/asset/validate")} >
            Validate Existing Asset
          </ButtonOk>
        </div>
      </div>
    </div>
  </Page>;
}
