import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useMutation } from "@tanstack/react-query";
import { Hash, Tuple } from "../Components";
import { ButtonCancel, ButtonOk } from "../Components/Buttons.js";
import { useActiveClient, TwinClient } from "../lib/client";
import { validateTwinUrl } from "../lib/validation.js";
import { Spinner, withSpinner } from "../Components/Spinner.js";
import { useAsset } from "./Asset.js";
import { usePersona } from "../Components/Address.js";
import { Page, PageTitle } from "../Components/page.js";

function UrlDisplay({url}) {
  let persona = usePersona(url);
  if (!persona) return url;
  if (typeof persona === "string") return persona;
  return `${persona?.name} -- ${persona?.email}`;
}

export default function TransferAsset() {
  let navigate = useNavigate();
  let { hash } = useParams();
  let { asset } = useAsset({ hash });
  let assetType = asset?.content?.assetType?.name;
  let twinClient = useActiveClient();

  let [ isTransferring, setIsTransferring] = useState(null);
  let { mutate: transferAsset } = useMutation({
    onMutate: _ => setIsTransferring(true),
    mutationFn: function transferAsset({hash, destination}) {
      console.log("submitting tx", hash, destination)
      return withSpinner(setIsSpinning, _ => twinClient.transferAsset({hash, destination}));
    },
    onSettled: _ => setIsTransferring(false),
    onSuccess: (data) => {
      console.log(data)
      navigate("/inventory")
      // make this prettier with some kind of feedback
      // - successful transaction
      // - transaction failed
      // - user clicks to navigate or attempt again
    }
  });

  let [ validUrl, setValidUrl ] = useState(null);
  function validateUrl(e) {
    let twinUrl = e.target.value;
    setTested(null);
    setValidUrl(null);

    let result = validateTwinUrl(twinUrl);
    if (result == null) {
      setValidUrl(twinUrl);
    }
  }

  let [ isSpinning, setIsSpinning ] = useState(null);

  let [ tested, setTested ] = useState(null);
  function testDestination(e) {
    // test with:
    // https://41d8fb5aff0b507e3ec715f4964c834d.tq.biz.todaq.net
    e.preventDefault();
    setTested(null);
    withSpinner(setIsSpinning, _ => TwinClient.test(validUrl)
      .then(res => {
        console.log(`URL test successful: ${res.address}`);
        setTested(res.address);
      }).catch(e => {
        console.warn(`URL test failed`, e);
        setTested(false);
      }));
  }

  function onSubmitForm(e) {
    e.preventDefault();
    if (!tested) {
      console.warn("Cannot send transaction; URL not tested!")
      return;
    }
    transferAsset({hash, destination: validUrl});
    // naming is kind of funky here
  }

  return <Page>
    <div className="h-full py-10 px-2 items-center justify-center">
      <div className="flex flex-col h-full p-6 w-full align-center">
        <PageTitle>TRANSFER ASSET</PageTitle>
        <Tuple>Asset <Hash h={hash} /></Tuple>
        {assetType && <Tuple>Type {assetType}</Tuple>}
        <form onSubmit={onSubmitForm}>
          <div>
            <label htmlFor="destination"
              className="block text-sm mb-2" >
              Send to</label>
            {assetType ?
              <select
                id="destination"
                name="destination"
                onChange={validateUrl}
                className="w-full px-2 py-2 rounded text-sm bg-zinc-700 disabled:text-zinc-400 text-zinc-100 invalid:text-error">
                  <option></option>
                {asset.content.assetType.authorizedRecipients.map(url =>
                  <option className="text-xs" key={url} value={url}><UrlDisplay url={url}/></option>)}
              </select> :
              <input id="destination"
                    placeholder="Enter destination url"
                    type="url"
                    required
                    onChange={validateUrl}
                    data-invalid={!validUrl}
                    className="w-full px-2 py-1 rounded text-sm bg-zinc-700 data-[invalid=true]:text-error text-zinc-100"
              />
            }
          </div>

          <div className="flex flex-row py-2 gap-2">
            <Spinner className={"!size-5"} hidden={!isSpinning }/>
            <div className="text-info" hidden={!tested || !isTransferring} >Transferring...</div>
            <div className="text-success" hidden={!tested || isTransferring}>Destination online</div>
            <div className="text-error" hidden={tested != false}>Destination not reachable</div>
          </div>
        </form>
      </div>
      <div className="flex w-full flex-row justify-between">
        <ButtonCancel type="button" onClick={() => navigate(`/asset/${hash}`)}>Back</ButtonCancel>
        <ButtonOk type="button" hidden={tested} disabled={!assetType && (!validUrl || isSpinning)} onClick={testDestination}>Test URL</ButtonOk>
        <ButtonOk type="submit" hidden={!tested} disabled={!tested || isSpinning} onClick={onSubmitForm}>Confirm transfer</ButtonOk>
      </div>
    </div>
  </Page>
}

// need history of previous destinations
