import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { TwinClient, useActiveClient } from "../lib/client";
import { validateTwinUrl } from "../lib/validation";
import { Hash, Tuple } from "../Components";
import { ButtonCancel, ButtonOk } from "../Components/Buttons";
import { TextInput } from "../Components/Input";
import { Spinner, withSpinner } from "../Components/Spinner";
import { useQuantitative } from "./Quant";
import { usePersona } from "../Components/Address";
import { Page, PageTitle } from "../Components/page";

function UrlDisplay({url}) {
  let persona = usePersona(url);
  if (!persona) return url;
  if (typeof persona === "string") return persona;
  return `${persona?.name} -- ${persona?.email}`;
}

export default function TransferQuant() {
  let navigate = useNavigate();
  let { type } = useParams();
  let { quant } = useQuantitative({type});
  // FIXME(sfertman): this tries to fetch /toda/undefined inside useAsset because
  //  it doesn't wait for /dq/:type to respond -- find a way for hooks to wait for each other
  let quantType = quant?.content?.assetType?.name;
  let twinClient = useActiveClient();

  // is transferring stuff and mutation here
  let [ isTransferring, setIsTransferring] = useState(null);
  let { mutate: transferAmount } = useMutation({
    onMutate: _ => setIsTransferring(true),
    mutationFn: function transferAmountMutation({type, amount, destination}) {
      console.log("submitting tx", type, amount, destination);
      return withSpinner(setIsSpinning, _ => twinClient.pay(destination, type, amount));
    },
    onSettled: _ => setIsTransferring(false),
    onSuccess: (data) => {
      console.log(data)
      navigate("/inventory")
      // make this prettier with some kind of feedback
      // - successful transaction
      // - transaction failed
      // - user clicks to navigate or attempt again
    }
  });

  let [ validUrl, setValidUrl ] = useState(null);
  function validateUrl(e) {
    let twinUrl = e.target.value;
    setTested(null);
    setValidUrl(null);

    let result = validateTwinUrl(twinUrl);
    if (result == null) {
      setValidUrl(twinUrl);
    }
  }

  let [ validAmount, setValidAmount ] = useState(null);
  function validateAmount(e) {
    setValidAmount(false);

    let amount = Number(e.target.value);
    if (!amount || !quant || !quant.quantity) return;
    if (amount > 0 && amount <= quant.quantity) {
      setValidAmount(true)
    }
  }


  let [ isSpinning, setIsSpinning ] = useState(null);

  let [ tested, setTested ] = useState(null);
  function testDestination(e) {
    // test with:
    // https://41d8fb5aff0b507e3ec715f4964c834d.tq.biz.todaq.net
    e.preventDefault();
    setTested(null);
    withSpinner(setIsSpinning, _ => TwinClient.test(validUrl)
      .then(res => {
        console.log(`URL test successful: ${res.address}`);
        setTested(res.address);
      }).catch(e => {
        console.warn(`URL test failed`, e);
        setTested(false);
      }));
  }

  function onSubmitForm(e) {
    e.preventDefault();
    if (!tested) {
      console.warn("Cannot send transaction; URL not tested!")
      return;
    }
    let formData = new FormData(e.target.form);
    let amount = Number(formData.get("transfer-amount"))
    if (amount > quant.quantity) { // This should never happen but we'll test anyway
      console.log("Insufficient balance!");
      return;
    }
    transferAmount({type, amount, destination: validUrl});
    // naming is kind of funky here
  }

  return <Page>
    <div className="h-full py-10 px-2 flex flex-col items-center justify-center">
      <div className="flex flex-col h-full p-6 w-full align-center text-nowrap">
        <PageTitle>TRANSFER AMOUNT</PageTitle>
        <Tuple>Type <Hash h={type}/></Tuple>
        <Tuple>Available Amount {`${quant?.quantity} ${quant?.content?.units || "N/A" }`} </Tuple>
        <form onSubmit={onSubmitForm}
          className="flex flex-col gap-6"
          >
          <TextInput
            id="transfer-amount"
            label="Transfer amount"
            type="number"
            placeholder={0}
            required
            onChange={validateAmount}
            data-invalid={!validAmount}
            className="data-[invalid=true]:text-error"
            pattern={/[0-9]+/}
          />
          {quantType ?
            <div>
              <label htmlFor="destination"
                      className="block text-sm font-medium mb-2 text-zinc-400" >
                Send to</label>
              <select
                id="destination"
                name="destination"
                onChange={validateUrl}
                className="w-full px-2 py-2 rounded text-sm bg-zinc-700 disabled:text-zinc-400 text-zinc-100 invalid:text-error">
                  <option></option>
                {quant.content.assetType.authorizedRecipients.map(url =>
                  <option className="text-xs" key={url} value={url}><UrlDisplay url={url} /></option>)}
              </select>
            </div>:
            <TextInput
              id="transfer-destination"
              label="Send to"
              placeholder="Enter destination url"
              type="url"
              required
              onChange={validateUrl}
              data-invalid={!validUrl}
              className="data-[invalid=true]:text-error"
            />
          }
          <div className="flex flex-row py-2 gap-2">
            <Spinner className={"!size-5"} hidden={!isSpinning }/>
            <div className="text-info" hidden={!tested || !isTransferring} >Transferring...</div>
            <div className="text-success" hidden={!tested || isTransferring}>Destination online</div>
            <div className="text-error" hidden={tested != false}>Destination not reachable</div>
          </div>
        </form>
      </div>
      <div className="flex flex-row w-full justify-between">
        <ButtonCancel type="button" onClick={() => navigate(-1)}>Back</ButtonCancel>
        <ButtonOk type="button" hidden={tested} disabled={!validUrl || !validAmount || isSpinning} onClick={testDestination}>Test URL</ButtonOk>
        <ButtonOk type="submit" hidden={!tested} disabled={!tested  || !validAmount || isSpinning} onClick={onSubmitForm}>Confirm transfer</ButtonOk>
      </div>
    </div>
  </Page>
}
