import { Abject } from "./todaadot/src/abject/abject.js";
import { Actionable } from "./todaadot/src/abject/actionable.js";
import { Hash } from "./todaadot/src/core/hash.js";
import { Twist } from "./todaadot/src/core/twist.js";
import { jsonTryParse } from "../util.js";

// following imports are required for parsing abject from twist
import { SimpleRigged } from "./todaadot/src/abject/actionable.js";
import { DQ } from "./todaadot/src/abject/quantity.js";

export function parseBytes (bytes) {
  let r = {};
  try {
    r.twist = Twist.fromBytes(bytes);
    r.abject = Abject.fromTwist(r.twist);
  } catch (e) {
    console.warn("Encountered error(s) parsing file bytes", e);
    return { _error: e, ...r };
  }

  return r;
}

export function ssrFromContext(di) {
  const symTimestamp = Abject.gensym("ssr/timestamp");
  const symContent = Abject.gensym("ssr/content");
  let timestamp = di.getFieldAbject(symTimestamp);
  let content = jsonTryParse(di.getFieldAbject(symContent));

  if (timestamp == null || content == null)
    return null; // not SSR

  return { timestamp, content };
}

/**
 *
 * @param {Twist} tw
 * @returns {Date}
 */
export function getTetherTs(tw) {
  const tsSym = Abject.gensym("field/relay/ts");
  let tether = tw.tether();
  if (tether) {
    let abj = Abject.fromTwist(tether);
    if (abj) {
      return abj.getFieldAbject(tsSym);
    }
  }
}

/**
 * @param {Actionable} di
*/
export function dqFromContext(di) {
  const symQuantity = Hash.fromHex("229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da37");
  const symDisplayPrecision = Hash.fromHex("2248a88ced3cb2ee7e8187fccc4d70dad8ec75bb8f01b5dbfcdf94ef0ce4fcaea4");
  const symMintingInfo = Hash.fromHex("220b0bfdd07d701255a52dff626c4a69b7af73e42061857b4b04537542c4e4ea52");

  let mintedQuantity = di.getFieldAbject(symQuantity);
  let displayPrecision = di.getFieldAbject(symDisplayPrecision);
  let content = jsonTryParse(di.getFieldAbject(symMintingInfo)); // this is minting info

  if (mintedQuantity == null || displayPrecision == null || content == null)
    return null; // not  DQ

  return { mintedQuantity, displayPrecision, content };
}
