import { b64toBlob } from "../util";

export default function Attachment({file}) {
  let { fileName, fileMime, content} = file;
  let blob = b64toBlob(content, fileMime);
  let url = URL.createObjectURL(blob)
  return <details className="w-full">
    <summary className="cursor-pointer">
      <span>{fileName || "attachment"}</span>
      <a href={url} download={fileName} title="Download asset"
        className="float-right  pl-7 h-5 hover:underline ~text-zinc-100 bg-[url(/public/img/download-file.svg)] bg-no-repeat ">
        Download
      </a>
    </summary>
    <p className="ml-3 px-2 py-1 rounded bg-zinc-700 text-zinc-100 text-wrap break-words">
      {fileMime.startsWith('image') && <img src={url} /> }
    </p>
  </details>
}
