import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProfiles } from "../lib/profiles.js";
import { TwinClient } from "../lib/client"
import { ButtonOk } from "../Components/Buttons.js";
import { validateTwinUrl } from "../lib/validation.js";
import { Page } from "../Components/page.js";

export default function Login() {
  const { setProfile, setActiveProfile } = useProfiles();
  const navigate = useNavigate();
  let [ searchParams, _ ] = useSearchParams();

  // Example twins we can work with:

  // https://41f33a6c397b4bca97bb5616acd7fe39.tracker-demo.biz.todaq.net
  // c99c7e58-a71e-4529-8b88-3cf5dc92a7a3

  // https://4103432af1f870c5289fb951ad236d7d.tracker-demo.biz.todaq.net
  // 69de0132-60ea-4b30-8faa-efbdbfa22ec6


  // Staging twins
  // https://411026958e9e2a4c7852367bbe018075.tq.biz-staging.todaq.net
  // 92a511a7-988a-4880-becb-101af1291b98

  // https://4125b9aaebfb403b51827de29887103b.tq.biz-staging.todaq.net
  // 9f671957-5c3d-4393-9fd4-122e8aec5a92

  useEffect(function writeLoginFromParams() {
    let twinUrl = searchParams.get("twin-url");
    let twinApiKey = searchParams.get("twin-api-key")
    if (twinUrl && twinUrlInput?.current) {
      twinUrlInput.current.value = twinUrl;
      validateUrl({target: twinUrlInput.current}) // HAACK
    }
    if (twinApiKey) {
      document.getElementById("twin-api-key").defaultValue = twinApiKey;
    }
  }, [searchParams]);

  async function doLogin(e) {
    e.preventDefault();
    let form = e.target;
    let formData = new FormData(form);
    let url = formData.get("url");
    let apiKey = formData.get("apiKey");
    let client = new TwinClient({url, apiKey});
    let result = await client.binder();
    // PERF(sfertman): maybe ping a lighter endpoint to verify creds
    if (!result) {
      return navigate("/login");
    } else {
      let {address, binderId} = await client.info();
      setProfile(url, {
        address,
        binderId,
        client: { url, apiKey },
      })
      setActiveProfile(url);
      return navigate("/home")
    }
  }

  let [ validUrl, setValidUrl ] = useState(null);
  function validateUrl(e) {
    let twinUrl = e.target.value;
    setValidUrl(null);

    let result = validateTwinUrl(twinUrl);
    if (result == null) {
      setValidUrl(twinUrl);
    }
  }

  let twinUrlInput = useRef(null);
  let twinApiKeyInput = useRef(null);

  return <Page>
    <div className="py-10 px-2 flex flex-col items-center justify-center">
      <div className="p-6 w-full align-center">
        <form onSubmit={doLogin}>
          <div className="grid gap-6 md:grid-rows-2">
            <div>
              <label
                htmlFor="twin-url"
                className="block mb-2 text-sm font-medium text-zinc-400">
                Account URL
              </label>
              <input
                id="twin-url"
                ref={twinUrlInput}
                type="text"
                name="url"
                onChange={validateUrl}
                onChangeCapture={validateUrl}
                data-invalid={!validUrl}
                placeholder="https://"
                className="p-1 border block w-full text-gray-800 data-[invalid=true]:text-red-600 " />
            </div>
            <div>
              <label
                htmlFor="twin-api-key"
                className="block mb-2 text-sm font-medium text-zinc-400">
                Account Access Code
              </label>
              <input
                id="twin-api-key"
                ref={twinApiKeyInput}
                type="password"
                required
                name="apiKey"
                placeholder="00000000-0000-0000-0000-000000000000"
                className="p-1 border block w-full text-gray-800" />
            </div>
            <div className="gap-6 flex flex-col">
              <ButtonOk type="submit" disabled={!validUrl}>
                Login
              </ButtonOk>
              <button
                type="button"
                onClick={() => navigate("/signup")}
                className="px-5 py-2
                  border-2 rounded-md
                  text-sm font-medium text-center
                  border-gray-500
                  focus:ring-2 focus:outline-none focus:ring-blue-300
                  text-gray-300
                  disabled:text-gray-700
                  disabled:border-gray-700
                  enabled:hover:bg-gray-500
                  enabled:hover:text-gray-800">
                Request Account
              </button>
              <ButtonOk onClick={() => navigate("/asset/validate")} >
                Validate Existing Asset
              </ButtonOk>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Page>;
}
