import { useNavigate } from "react-router-dom";
import { ProfileManager } from "./ProfileManager";
import info from "../info.json"

export function Header() {
  let navigate = useNavigate();
  return <div className="flex flex-col p-2 mb-4">
    <div className="flex flex-row justify-between w-full">
      <div className="h-[2rem] aspect-logo">
        <img className="cursor-pointer" src="/img/logo.png" onClick={() => navigate("/home")} />
      </div>
      <div className="text-[1.5rem] text-zinc-400 font-bold ml-3">
        Asset Tracker
      </div>
      <ProfileManager />
    </div>
  </div>;
}

export function Footer() {
  return <div className="text-[0.75rem] flex items-center justify-between  w-full text-toda-gray  ~text-gray-800">
    <div>TRACKER by TODAQ 2024</div>
    <div className="text-center">CORPORATE CONFIDENTIAL</div>
    <div> Build: {info.build} {info.env && `@${info.env}`} </div>
  </div>
}

export function PageTitle({children}) {
  return <div className="my-4 font-semibold underline uppercase">
    {children}
  </div>
}

export function Page({children}) {
  return <div className="h-full flex flex-col">
    <Header />
    <div className="grow">
      {children}
    </div>
    <Footer/>
  </div>
}
