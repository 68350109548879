import { filterObject } from "../util";

export const ASSET_TYPES = {
  class8bolt: {
    name: "Class 8 bolt",
    authorizedCreators: [
      "https://411a0ff4e03ac5e76ee15d4657f0f62b.tracker-demo.biz.todaq.net",
      "https://4180a6f16b25385ed1e8bee96b24bb6e.tracker-demo.biz.todaq.net",
    ],
    authorizedRecipients: [
      "https://414252024d89b696b6f1af71d3bf6d3c.tracker-demo.biz.todaq.net",
      "https://4117b5ea693cb1db9432ac15b7429e25.tracker-demo.biz.todaq.net",
      "https://416ee58d89e4f8d6e4b2a69784143ad8.tracker-demo.biz.todaq.net",
    ]
  }
}

export function getAuthorizedCreators(id) {
  return ASSET_TYPES[id]?.authorizedCreators;
}

export function getCreatorTypes(twinUrl) {
  return filterObject(ASSET_TYPES, ([_, v]) => v.authorizedCreators.indexOf(twinUrl) != -1);
}

export function getAuthorizedRecipients(id) {
  return ASSET_TYPES[id]?.authorizedRecipients;
}
