import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { HashDisplay } from "./index.js"; // FIXME: circular deps
import { AdminTwinClient, TwinClient } from "../lib/client";

export function useTwinLookup(hash) {
  let { data: twinUrl } = useQuery({
    queryKey: ["lookup", hash],
    queryFn: async function lookupFn() {
      let adminClient = new AdminTwinClient({url:"https://admin.tracker-demo.biz.todaq.net"});
      return await adminClient.lookup(hash);
    },
    enabled: !!hash,
    staleTime: Infinity
  });
  return twinUrl;
}

export function usePersona(twinUrl) {
  let { data: persona } = useQuery({
    queryKey: ["GET", twinUrl, "/persona"],
    queryFn: async function getPersonaFn() {
      if (!twinUrl) return null;
      let res = await TwinClient.persona(twinUrl);
      let info = res?.instances[0]?.info;
      try {
        return JSON.parse(info);
      } catch (_) {
        return info;
      }
    },
    enabled: !!twinUrl,
    retry: false,
    staleTime: Infinity
  });
  return persona;
}

export function usePersonaOrAddress(hash) {
  let twinUrl = useTwinLookup(hash);
  let { data: address } = useQuery({
    queryKey: ["address", twinUrl],
    queryFn: async function getAddressFn() {
      return await (new TwinClient({url: twinUrl})).address();
    },
    enabled: !!twinUrl,
    retry: false,
    staleTime: Infinity
  });
  let persona = usePersona(twinUrl);
  return { persona, address };
}


export function AddressCard({ h, state }) {
  if (!state) {
    state = useState(false);
  }

  let { persona, address } = usePersonaOrAddress(h);
  function AddressDisplay({ h }) {
    return <div className="rounded flex justify-end ~max-w-fit">
      {persona ?
        <div className="text-nowrap">
          {typeof persona === "string" ?
            <div>{persona}</div>
            :
            <>
              <div >{persona?.name}</div>
              <div className="text-xs float-left">{persona?.email}</div>
            </>
          }
        </div>
        :
        <div className="flex flex-row group/address" >
          <HashDisplay h={address || h} fontSize={"14px"} />
        </div>
      }
    </div>
  }

  return <AddressDisplay h={h} />;
}
