import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Hash, HashDisplay, Tuple } from "../Components";
import { useAsset } from "./Asset";
import { ButtonCancel, ButtonOk } from "../Components/Buttons";
import { useActiveClient } from "../lib/client";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../Components/Spinner";
import { toDTG } from "../util";
import { Twist } from "../lib/todaadot/src/core/twist";
import { Page, PageTitle } from "../Components/page";
import Attachment from "../Components/Attachment";

export function QuantComponent({ client, quant }) {
  if (!quant || !quant.content) return <></>;
  let navigate = useNavigate();
  let date = quant.content.timestamp ? toDTG(quant.content.timestamp) : "Unknown";
  let quantType = quant.content.assetType?.name

  let { attachments } = quant.content;
  if (!attachments) {
    // NOTE(sfertman): backward compatibility
    attachments = [quant.content]
  }

  return <div className="flex flex-col items-center justify-between">
    <div className="w-full align-center ~text-nowrap">
      <PageTitle>{quant.content.name}</PageTitle>
      {quantType && <Tuple>Asset Type {quantType}</Tuple>}
      <Tuple position="00">Issuer ID <Hash h={quant.root.tether().getHash().toString()} /></Tuple>
      <Tuple position="01">Date {date}</Tuple>
      <Tuple>Asset Class <Hash h={quant.root.getHash().toString()}/></Tuple>
      <Tuple>Amount {`${quant.quantity} ${quant.content.units}`}</Tuple>
      <Tuple position="09">Description {quant.content.description || "N/A"}</Tuple>
      <Tuple position="42">
        Files
        <ul>
          {Object.entries(quant.fileValue).map(([h,v]) =>
            <li key={h}
                className="hover:underline cursor-pointer flex"
                onClick={() => navigate(`/asset/${h}`)}>
              <HashDisplay h={h}/>
              <p className="px-4 font-mono text-right">{v}</p>
            </li>) }
        </ul>
      </Tuple>
      <div className="flex flex-col w-full gap-4 my-2 items-baseline">
        <p>Attachments</p>
        <ul className="flex-col flex gap-2 w-full">
          {attachments.map(a => <li key={`${a.fileName}|${a.fileMime}`}>
            <Attachment file={a} />
          </li>)}
        </ul>
      </div>
    </div>
  </div>

}

export default function Quant() {
  let navigate = useNavigate();
  let { type } = useParams();
  let twinClient = useActiveClient();

  let { quant, isPending, isError, error } = useQuantitative({type})

  if (isPending) {
    return <Page>
      <div className="flex gap-2">
        <Spinner className="!size-5"/>
        <div className="flex gap-2 text-info align-text-bottom">
          Retrieving asset <HashDisplay h={type} />
        </div>
      </div>
    </Page>
  }

  if (!isPending && isError) {
    if (error?.data?.status == 404) {
      return <Page>
        <div className="flex flex-col gap-6">
          <div className="bg-red-800 flex gap-1 justify-center" >
            Quantitative <HashDisplay h={type}/> not found!
          </div>
          <ButtonCancel onClick={() => navigate(`/inventory`)}>
            Back to inventory
          </ButtonCancel>
        </div>
      </Page>
    }

    return <Page>
      <div className="flex flex-col gap-6">
        <div className="gap-6 bg-red-800">
          <div className="bg-red-800 text-center" >
            Error retrieving quantitative <Hash h={type} />
          </div>
          <pre className="bg-red-800 mt-6 p-2">
            {JSON.stringify(error.data, null, 2)}
          </pre>
        </div>
        <ButtonCancel onClick={() => navigate(`/inventory`)}>
          Back to inventory
        </ButtonCancel>
      </div>
    </Page>
  }

  if (!quant) return <></>;

  return <Page>
    <QuantComponent client={twinClient} quant={quant}/>
    <div className="flex grow my-4 px-6 w-full flex-row justify-center">
      <ButtonCancel hidden={!!quant} onClick={() => navigate(`/inventory`)}>
        Back to inventory
      </ButtonCancel>
      <ButtonOk hidden={!quant} onClick={() => navigate(`/quant/${type}/transfer`)}>
        Transfer
      </ButtonOk>
    </div>
  </Page>
}

export function useQuantitative({type}) {
  let twinClient = useActiveClient();

  let queryResult = useQuery({
    queryKey: ["GET", `/dq/${type}`],
    queryFn: async function balanceQuery() {
      return await twinClient.balance(type)
    }
  });

  let { asset } = useAsset({ hash: queryResult.data?.files[0] });

  let quant = useMemo(function() {
    if (!queryResult.data || !asset ) return null;
    let quant = {
      ...asset,
      root: new Twist(asset.abject.getAtoms(), asset.abject.rootId()),
      content: asset.content
    }
    return quant ;

  }, [queryResult.data, asset]);

  return {
    ...queryResult,
    quant: {
      ...queryResult.data,
      ...quant
    }
  };
}
