import { useEffect, useMemo, useRef, useState } from "react";
import { AssetComponent, fromBytes as assetFromBytes } from "./Asset.js";
import { Header } from "../Components";
import { Page } from "../Components/page.js";

export default function ValidateAsset() {
  let [ file, setFile ] = useState(null);
  let fileInput = useRef(null);

  let [ clicked, setClicked ] = useState(false);
  useEffect(() => {
    // NOTE(sfertman): I'm out of ideas on how to convince react
    // to not useEffect twice; this is my hack and I'm sticking
    // with it.
    if(!clicked) {
      if (fileInput?.current) {
        fileInput.current.click();
        setClicked(true)
      }
    }
  }, [clicked, fileInput]);


  async function fileSelect(e) {
    e.preventDefault();
    let fileInput = e.target.files?.[0];
    if (fileInput) {
      setFile({
        name: fileInput.name,
        bytes: await fileInput.arrayBuffer()
      });
    }
  }

  let asset = useMemo(function parseFileBytes() {
    let fileBytes = file?.bytes;
    if (!fileBytes) return null;
    if (fileBytes.data) return null;

    return assetFromBytes(fileBytes);

  }, [file]);

  function FileInput() {
    return <input
      ref={fileInput}
      type="file"
      className="file:border-0 file:text-white file:bg-gray-500 file:rounded-md file:font-medium file:text-sm file:px-5 file:py-2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
      onChange={fileSelect}
    />;
  }

  if (!asset) {
    return <Page>
      <p className="my-4">Select asset to validate</p>
      <FileInput />
    </Page>
  } else {
    return <Page>
      <AssetComponent asset={asset}/>
      <p className="my-4">Select another asset to validate</p>
      <FileInput/>
    </Page>
  }
}
