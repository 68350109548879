import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import { Twist } from "../lib/todaadot/src/core/twist.js";
import { Abject } from "../lib/todaadot/src/abject/abject.js";
import { DQ } from "../lib/todaadot/src/abject/quantity.js";
import { SimpleRigged } from "../lib/todaadot/src/abject/actionable.js";
import { useActiveClient } from "../lib/client";
import { Hash, HashDisplay, Tuple } from "../Components";
import { ButtonCancel, ButtonOk } from "../Components/Buttons.js";
import { Spinner } from "../Components/Spinner.js";
import { toDTG } from "../util.js";
import { dqFromContext, getTetherTs, parseBytes,ssrFromContext } from "../lib/abject.js";
import { Page, PageTitle } from "../Components/page.js";
import Attachment from "../Components/Attachment.js";

export function AssetComponent({client, asset}) {
  function ErrorElement({children}) {
    return <div className="rounded-md px-4 py-2 border-2 border-error text-error">
      <p>Error:</p>
      {children}
    </div>
  }
  let navigate = useNavigate();
  if (asset._error) {
    return <ErrorElement>
      {asset._error.message}
    </ErrorElement>
  }

  if (!asset.type) {
    return <ErrorElement>
      Asset is not of type Unique or Quant
    </ErrorElement>
  }

  if (!asset || !asset.type || !asset.content) {
    return <ErrorElement>
      Asset is not a valid tracker asset
    </ErrorElement>
  }

  if (!asset.content.content && !asset.content.attachments ) {
    return <ErrorElement>
      Asset has no content to display
    </ErrorElement>
  }

  let date = asset.timestamp && toDTG(asset.timestamp)
  if (!date) date = getTetherTs(asset.first);
  if (!date) date = "Unknown";

  let { attachments } = asset.content;
  if (!attachments) {
    // NOTE(sfertman): backward compatibility
    attachments = [asset.content]
  }

  let assetType = asset.content.assetType?.name;

  return <>
    <div className="flex flex-col items-center justify-between">
      <div className="w-full align-center ">
        <PageTitle> {asset.content.name} ({asset.type}) </PageTitle>
        {assetType && <Tuple>Asset Type {assetType}</Tuple>}
        {client &&
          <div className="mb-5 h-5 bg-[url(/public/img/download-file.svg)] bg-no-repeat">
            <a  href={client.downloadLink(asset.twist.getHash()).toString()}
                title="Download asset"
                className="pl-7 hover:underline text-zinc-100">
              Download asset
            </a>
          </div> }
        <Tuple position="00">Issuer ID <Hash h={asset.first.tether().getHash().toString()} /></Tuple>

        <Tuple position="01">Date {date}</Tuple>
        <Tuple position="02">Initial Owner ID <Hash h={asset.owners[asset.owners.length-1]} /></Tuple>
        { asset.owners.length > 2 &&
          <Tuple position="03">
            Prior owners
            <ul>{asset.owners.slice(1,-1).map(h =>
              <li key={ h } className="text-left"><Hash h={h} /></li>)}
            </ul>
          </Tuple>}
        <Tuple position="04">Current Owner <Hash h={asset.owners[0]} /></Tuple>
        {/* // HAAAACK(sfertman): ^^ see issue #41 */}
        {asset.type == "Quant" ? <>
          <Tuple position="05">
            Type ID
            <div  className="hover:underline cursor-pointer text-sm"
                  onClick={() => navigate(`/quant/${asset.abject.root().getHash().toString()}`)}>
              <HashDisplay h={asset.abject.root().getHash().toString()} />
            </div>
          </Tuple>
          <Tuple position="06">Amount {asset.quantity}</Tuple>
          <Tuple position="07">Units {asset.content.units}</Tuple>
          {/* <Tuple position="???">Decimal precision {asset.displayPrecision}</Tuple> */}
        </>: <></> }
        <Tuple position="09">Description {asset.content.description || "N/A"}</Tuple>
        <Tuple position="10">Asset Integrity Line <span>TODAQ</span></Tuple>
        <Tuple position="11">Instant Relay <span>TODAQ</span></Tuple>
        <div className="flex flex-col w-full gap-4 my-2 items-baseline">
          <p>Attachments</p>
          <ul className="flex-col flex gap-2 w-full">
            {attachments.map(a => <li key={`${a.fileName}|${a.fileMime}`}>
              <Attachment file={a} />
            </li>)}
          </ul>
        </div>
      </div>
    </div>
  </>
}

export default function Asset() {
  let navigate = useNavigate();
  let { hash } = useParams();
  let twinClient = useActiveClient();

  let { asset, isPending, isError, error } = useAsset({hash})
  if (isPending) {
    return <Page>
      <div className="flex gap-2">
        <Spinner className="!size-5"/>
        <div className="flex gap-2 text-info align-text-bottom">
          Retrieving asset <HashDisplay h={hash} />
        </div>
      </div>
    </Page>
  }

  if (!isPending && isError) {
    if (error?.data?.status == 404) {
      return <Page>
        <div className="flex flex-col gap-6">
          <div className="bg-red-800 flex gap-1 justify-center" >
            Asset <HashDisplay h={hash}/> not found!
          </div>
          <ButtonCancel onClick={() => navigate(`/inventory`)}>
            Back to inventory
          </ButtonCancel>
        </div>
      </Page>
    }

    return <Page>
      <div className="flex flex-col gap-6">
        <div className="gap-6 bg-red-800">
          <div className="bg-red-800 text-center" >
            Error retrieving asset <Hash h={hash} />
          </div>
          <pre className="bg-red-800 mt-6 p-2">
            {JSON.stringify(error.data, null, 2)}
          </pre>
        </div>
        <ButtonCancel onClick={() => navigate(`/inventory`)}>
          Back to inventory
        </ButtonCancel>
      </div>
    </Page>
  }

  if (!asset) return <Page></Page>

  return <Page>
    <AssetComponent client={twinClient} asset={asset} />
    <div className="flex grow my-4 px-6 w-full flex-row justify-center">
      <ButtonCancel hidden={!!asset} onClick={() => navigate(`/inventory`)}>
        Back to inventory
      </ButtonCancel>
      <ButtonOk hidden={!asset} onClick={() => navigate(`/asset/${hash}/transfer`)}>
        Transfer
      </ButtonOk>
    </div>
  </Page>
}

export function useAsset({hash}) {
  let twinClient = useActiveClient();
  let fetchQueryObject = useQuery({
    queryKey: ["GET", `/toda/${hash}`],
    queryFn: async function fetchFileQuery() {
      return await twinClient.fetch(hash)
        .then(buff => new Uint8Array(buff))
    },
    staleTime: 1000*60*5
  });

  let asset = useMemo(function parseFileBytes() {
    let bytes = fetchQueryObject.data;
    if (!bytes) return null;
    if (bytes.data) return null;

    return fromBytes(bytes);

  }, [fetchQueryObject.data]);

  return { ...fetchQueryObject, asset }
}

export function fromBytes(bytes) {
  let parsed = parseBytes(bytes);
  if (parsed._error) {
    return parsed;
  }
  parsed.first = parsed.twist.first();
  let atoms = parsed.twist.getAtoms();
  parsed.history = parsed.twist.knownHistory().map(h => new Twist(atoms, h));
  try {
    parsed.owners = parsed.history.map(tw => tw.getTetherHash()?.toString()).filter(h => !!h);
  } catch (e) {
    console.warn(`Error getting owners for ${parsed.twist.getHash()}`, e);
  }
  let assetProps;
  if (parsed.abject instanceof DQ) {
    assetProps = dqFromContext(parsed.abject.rootContext());
    if (assetProps) {
      assetProps.type = "Quant";
      assetProps.quantity = parsed.abject.quantity;
    }
  } else if (parsed.abject instanceof SimpleRigged) {
    let firstAbject = Abject.fromTwist(parsed.first);
    if (!firstAbject) return parsed;
    assetProps = ssrFromContext(firstAbject.getContext());
    if (assetProps) {
      assetProps.type = "Unique";
    }
  }

  return {
    ...parsed,
    ...assetProps
  };
}
