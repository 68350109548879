import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { ButtonCancel, ButtonOk } from "../Components/Buttons.js";
import { useActiveClient } from "../lib/client";
import { Spinner, withSpinner } from "../Components/Spinner.js";
import { TextInput } from "../Components/Input.js";
import { bytesTob64 } from "../util.js";
import { ASSET_TYPES, getCreatorTypes } from "../lib/asset-types.js";
import { Page, PageTitle } from "../Components/page.js";

function CreateUniqueAssetForm() {
  let navigate = useNavigate();
  let twinClient = useActiveClient();

  let { mutate: createSSR } = useMutation({
    mutationFn: function createSSR(content) {
      return withSpinner(setIsSpinning, _ => twinClient.createSSR(content))
    },
    onSuccess: function createSSROnSuccess(data) {
      navigate(`/asset/${data.twistId}`)
    }
  });

  let [files, setFiles] = useState(null)

  async function fileSelect(e) {
    e.preventDefault();
    let { files: inputFiles } = e.target;
    if (inputFiles.length > 0) {
      let _files = [];
      for (let i=0; i < inputFiles.length; i++) {
        // NOTE(sfertman): special object, cannot use array methods here
        _files.push({
          content: bytesTob64(await inputFiles[i].arrayBuffer()),
          fileName: inputFiles[i].name,
          fileMime: inputFiles[i].type
        });
      }
      setFiles(_files);
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    let formData = new FormData(e.target);

    let id = formData.get("unique-asset-type");
    let assetType = { id, ...ASSET_TYPES[id] };

    createSSR({
      assetType,
      name: formData.get("name"),
      description: formData.get("description"),
      attachments: files.map(({ fileName, fileMime, content }) => {
        return { fileName, fileMime, content };
      })
    });
  }

  function cancelCreate(_) {
    return navigate(-1)
  }
  let fileInput = useRef(null);

  let [ isSpinning, setIsSpinning ] = useState(null);

  let creatorTypes = useMemo(() => getCreatorTypes(twinClient.url), [twinClient.url]);

  return <form onSubmit={onSubmitForm} >
    <div className="grid gap-6 grid-rows-1 ">
      <TextInput
        label="Asset name"
        id="create-asset-name"
        name="name"
        required
        placeholder="Enter asset name" />
      {Object.keys(creatorTypes).length > 0 &&
        <div className="flex flex-col gap-2" >
          <label htmlFor="unique-asset-type"
                className="block text-sm font-medium text-zinc-400">
            Asset Type
          </label>
          <select id="unique-asset-type"
                  name="unique-asset-type"
                  defaultValue="untyped"
                  className="w-full px-2 py-2 rounded text-sm bg-zinc-700 disabled:text-zinc-400 text-zinc-100 invalid:text-error">
            <option value="untyped">Not applicable</option>
            {Object.entries(creatorTypes).map(([k,v]) =>
              <option key={k} value={k}>{v.name}</option>)}
          </select>
        </div>
      }
      <div className="flex flex-col gap-2">
        <label htmlFor="create-asset-desc"
              className="block text-sm font-medium text-zinc-400">
          Description
        </label>
        <textarea
          id="create-asset-desc"
          name="description"
          placeholder="Enter description"
          className="w-full px-2 py-1 rounded text-sm bg-zinc-700 disabled:text-zinc-400  text-zinc-100 invalid:text-error">
        </textarea>
      </div>
      <input ref={fileInput}
            type="file"
            multiple
            className="text-sm file:border-0 file:text-white file:bg-gray-500 file:rounded-md file:font-medium file:text-sm file:px-5 file:py-2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
            onChange={fileSelect}/>
      <div className="flex">
        <Spinner hidden={!isSpinning} className="!size-5"/>
        <div hidden={!isSpinning} className="text-info mx-2">Creating...</div>
      </div>
      <div className="fixed flex grow left-0 px-6 bottom-8 ~right-0 w-full flex-row justify-between">
        <ButtonCancel type="button" onClick={cancelCreate}>Back</ButtonCancel>
        <ButtonOk type="submit" disabled={!files || isSpinning}>Create</ButtonOk>
      </div>
    </div>
  </form>
}

function CreateQuantAssetForm() {
  let navigate = useNavigate();
  let twinClient = useActiveClient();

  let { mutate: mintDq } = useMutation({
    mutationFn: function mintDqMutation(args) {
      return withSpinner(setIsSpinning, _ => twinClient.mintDQ(args))
    },
    onSuccess: function createQuantOnSuccess(data) {
      navigate(`/quant/${data.root}`);
    }
  });

  let [files, setFiles] = useState(null)

  async function fileSelect(e) {
    e.preventDefault();
    let { files: inputFiles } = e.target;
    if (inputFiles.length > 0) {
      let _files = [];
      for (let i=0; i < inputFiles.length; i++) {
        // NOTE(sfertman): special object, cannot use array methods here
        _files.push({
          content: bytesTob64(await inputFiles[i].arrayBuffer()),
          fileName: inputFiles[i].name,
          fileMime: inputFiles[i].type
        });
      }
      setFiles(_files);
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    let formData = new FormData(e.target);

    let id = formData.get("quant-asset-type");
    let assetType = { id, ...ASSET_TYPES[id] };

    mintDq({
      quantity: Number(formData.get("amount")),
      displayPrecision: Number(formData.get("precision") || 0),
      mintingInfo: JSON.stringify({
        assetType,
        name: formData.get("name"),
        units: formData.get("units"),
        timestamp: new Date(),
        description: formData.get("description"),
        attachments: files.map(({ fileName, fileMime, content }) => {
          return { fileName, fileMime, content };
        })
      })
    });
  }

  function cancelCreate(_) {
    return navigate(-1)
  }

  let fileInput = useRef(null);

  let [ isSpinning, setIsSpinning ] = useState(null);

  let creatorTypes = useMemo(() => getCreatorTypes(twinClient.url), [twinClient.url]);

  return <form onSubmit={onSubmitForm}>
    <div className="grid gap-6 grid-rows-1">
      <TextInput
        label="Asset name"
        id="create-asset-name"
        name="name"
        required
        placeholder="Enter asset name" />
      {Object.keys(creatorTypes).length > 0 &&
        <div className="flex flex-col gap-2" >
          <label htmlFor="quant-asset-type"
                className="block text-sm font-medium text-zinc-400">
            Asset Type
          </label>
          <select id="quant-asset-type"
                  name="quant-asset-type"
                  defaultValue="untyped"
                  className="w-full px-2 py-2 rounded text-sm bg-zinc-700 disabled:text-zinc-400 text-zinc-100 invalid:text-error">
            <option value="untyped">Not applicable</option>
            {Object.entries(creatorTypes).map(([k,v]) =>
              <option key={k} value={k}>{v.name}</option>)}
          </select>
        </div>
      }
      <TextInput
        label="Amount"
        id="create-asset-amount"
        name="amount"
        required
        placeholder="Enter amount"
        type="number"/>
      <TextInput
        label="Units"
        id="create-asset-units"
        name="units"
        required
        placeholder="Enter units"/>
      {/* <TextInput
        label="Decimal precision"
        id="create-asset-precision"
        name="precision"
        required
        defaultValue={0}
        placeholder={0}
        type="number"/> */}
      <div className="flex flex-col gap-2">
        <label htmlFor="create-asset-desc"
              className="block text-sm font-medium text-zinc-400" >
          Description
        </label>
        <textarea
          id="create-asset-desc"
          name="description"
          placeholder="Enter description"
          className="w-full px-2 py-1 rounded text-sm bg-zinc-700 disabled:text-zinc-400  text-zinc-100 invalid:text-error">
        </textarea>
      </div>
      <input ref={fileInput}
             type="file"
             multiple
             className="text-sm file:border-0 file:text-white file:bg-gray-500 file:rounded-md file:font-medium file:text-sm file:px-5 file:py-2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
             onChange={fileSelect}/>
      <div className="flex">
        <Spinner hidden={!isSpinning} className="!size-5"/>
        <div hidden={!isSpinning} className="text-info mx-2">Creating...</div>
      </div>
      <div className="fixed flex grow left-0 px-6 bottom-8 ~right-0 w-full flex-row justify-between">
        <ButtonCancel type="button" onClick={cancelCreate}>Back</ButtonCancel>
        <ButtonOk type="submit" disabled={!files || isSpinning}>Create</ButtonOk>
      </div>
    </div>
  </form>
}

export default function CreateAsset() {

  let [ assetType, setAssetType ] = useState("unique")
  function onSelectAssetType(e) {
    setAssetType(e.target.value)
  }

  return <Page>
    <PageTitle>CREATE ASSET</PageTitle>
    <div className="flex flex-row w-full justify-evenly my-4 text-sm ">
      <input id="unique-asset"
            type="radio"
            name="asset-type"
            value="unique"
            defaultChecked
            onChange={onSelectAssetType}
            hidden/>
      <label htmlFor="unique-asset"
             data-asset-type={assetType}
             className="w-full border-2 border-r-0 rounded-l-md py-2 border-gray-600
                        text-center
                      text-gray-600
                      data-[asset-type=unique]:border-gray-500
                      data-[asset-type=unique]:text-white
                        data-[asset-type=unique]:font-medium
                      data-[asset-type=unique]:bg-gray-500">Unique</label>
      <input id="quant-asset"
            type="radio"
            name="asset-type"
            value="quant"
            onChange={onSelectAssetType}
            hidden/>
      <label htmlFor="quant-asset"
             data-asset-type={assetType}
             className="w-full border-2 border-l-0 rounded-r-md py-2 border-gray-600
                        text-center
                      text-gray-600
                      data-[asset-type=quant]:border-gray-500
                      data-[asset-type=quant]:text-white
                        data-[asset-type=quant]:font-medium
                      data-[asset-type=quant]:bg-gray-500" >Quant</label>
    </div>
    {assetType=="unique"?<CreateUniqueAssetForm/>:<CreateQuantAssetForm/>}
  </Page>
}
